import React from 'react'
import { graphql } from 'gatsby'
import { HeroVideo, Layout } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '../pageBuilder/wrapper'

const IndexPage = ({ data, pageContext }) => {
  const {
    moduleArea,
    seoMetaTags,
    heroText,
    heroVideo,
    heroThumbnail
  } = data.home
  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <HeroVideo
        text={heroText}
        video={heroVideo}
        thumbnail={heroThumbnail.url}
      />
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      id
      title
      heroText
      heroVideo {
        url
        video {
          mp4Url
        }
      }
      heroThumbnail {
        url(imgixParams: { w: "1920", h: "1080", fit: "crop", q: 50 })
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsBanner {
          ...Banner
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsCardSlider {
          ...CardSlider
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
        ... on DatoCmsVideoBanner {
          ...BannerVideo
        }
      }
    }
    allSitePage {
      nodes {
        ...MenuHelpersAllPages
      }
    }
  }
`
